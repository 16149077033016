import React, { memo } from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import Contact from '../components/Contact';
import Seo from '../components/Seo';
import ScrollButton from '../components/ScrollButton';

import config from '../../config';
import { Card } from 'reactstrap';
import { Typography, Grid, CardContent } from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const IndexPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "ai.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `
  );

  const image = getImage(backgroundImage123);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead"
      alt="AI Consulting"
    >
      <Layout>
      <Seo
          title="AI Consulting & Strategy"
          description="Surge Consulting specializes in AI strategy, readiness assessment, custom solutions, and process automation to transform your business."
        />
        <ScrollButton />
        <section id="banner">
          <div className="inner">
            <h2>{config.heading}</h2>
            {/* <h3>{config.subHeading}</h3> */}
            <ul className="actions special">
              <li>
                <Scroll type="id" element="cta">
                  <a href="#cta" className="button primary">
                    AI Readiness Assessment
                  </a>
                </Scroll>
              </li>
            </ul>
          </div>
          <Scroll type="id" element="one">
            <a href="#one" className="more">
              Learn More
            </a>
          </Scroll>
        </section>

        <section id="one" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>
              Accelerate Your Business with  &nbsp;
                <br />
                Advanced AI Solutions
              </h2>
              {/* <h1>Your Agility Partner</h1> */}
              <p>
                Our comprehensive AI consulting services empower businesses to innovate and thrive by: Developing strategic AI roadmaps, Delivering custom AI solutions and Automating key processes for improved productivity.
              </p>
            </header>

            <Grid container>
              <Grid item xs={12} md={4}>
                <Card className="card-equal-height" sx={{ maxWidth: 345 }}>
                  <CardContent className="card-content">
                    <StaticImage
                      src="../assets/images/cards/service-icon-01.png"
                      alt="AI Strategy"
                      placeholder="BLURRED"
                      quality="60"
                    />
                    <Typography gutterBottom variant="h5" component="div">
                    AI 
                      <br />
                      Strategy
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    Craft a strategic roadmap that drives seamless AI adoption and integration, helping your business transform processes, enhance decision-making, and navigate the challenges of digital transformation with confidence.

                    </Typography>
                  </CardContent>
                  <div className="button-container">
                  <a href="/projectdelivery" className="button primary">
                      How We Partner
                  </a>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className="card-equal-height" sx={{ maxWidth: 345 }}>
                  <CardContent className="card-content">
                    <StaticImage
                      src="../assets/images/cards/service-icon-02.png"
                      alt="AI Solutions"
                      placeholder="BLURRED"
                      quality="60"
                    />
                    <Typography gutterBottom variant="h5" component="div">
                    Custom AI 
                      <br />
                      Solutions
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    Tailor-made AI solutions that empower businesses to overcome challenges by uncovering patterns in data, predicting future trends, and automating labor-intensive tasks. Unlock efficiency and insight to stay ahead of the competition.

                    </Typography>
                  </CardContent>
                  <div className="button-container">
                  <a href="/agilitytransformation" className="button primary">
                  How We Deliver
                  </a>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className="card-equal-height" sx={{ maxWidth: 345 }}>
                  <CardContent className="card-content">
                    <StaticImage
                      src="../assets/images/cards/service-icon-03.png"
                      alt="AI process automation"
                      placeholder="BLURRED"
                      quality="60"
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Process
                      <br />
                      Optimisation
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    Harness the power of AI to streamline workflows and boost productivity. We build intelligent automation solutions such as chatbots, RPA, and virtual assistants that integrate seamlessly with your existing systems to optimize processes and deliver measurable business results.

                    </Typography>
                  </CardContent>
                  <div className="button-container">
                  <a
                    href="https://surgetechnology.co.nz"
                    className="button primary"
                  >
                    How We Succeed
                  </a>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </section>

        <section id="two" className="wrapper alt style2">
          <section className="spotlight">
            <div className="image">
              <StaticImage
                src="../assets/images/pic01.jpg"
                alt="AI Performance Optimization"
                placeholder="BLURRED"
                quality="60"
              />
            </div>
            <div className="content">
              <h2>
                Performance Optimization
              </h2>
              <p>
                Achieve unparalleled business efficiency with intelligent automation. Our process automation solutions use AI to minimize manual workflows, streamline operations, and empower your team to focus on high-value tasks.
              </p>
              <a href="/ai-performance-score" className="button primary">
                AI Performance Score
              </a>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <StaticImage
                src="../assets/images/pic03.jpg"
                alt="Agility Transformation with AI"
                placeholder="BLURRED"
              />
            </div>
            <div className="content">
              <h2>
                Agility Transformation with AI
              </h2>
              <p>
                AI-driven agility helps businesses swiftly adapt to market shifts, customer demands, and competitive pressures. Our comprehensive AI strategy and readiness assessments provide a roadmap for seamless transformation, empowering your organization to thrive.
              </p>
              <a href="/agility-transformation" className="button primary">
                Begin Your Transformation
              </a>
            </div>
          </section>
        </section>

        <section id="three" className="wrapper style3 special">
          <div className="inner">
            <header className="major">
              <h2>How We Work</h2>
              <p>
                Surge offers AI Strategy, Readiness Assessment, Custom AI Solutions, and Process Automation. Our team is available for both consulting engagements and contract projects to help you navigate digital transformation and create lasting value.
              </p>
              <ul>
                <li>
                  Strategy: Develop a comprehensive roadmap for AI adoption aligned with your business goals.
                </li>
                <li>
                  Readiness Assessment: Evaluate your organization's readiness for AI transformation and identify key growth opportunities.
                </li>
                <li>
                  Custom Solutions: Create tailor-made AI tools like predictive analytics and recommendation engines.
                </li>
                <li>
                  Process Automation: Streamline operations with chatbots, RPA, and other intelligent automation tools.
                </li>
              </ul>
              <br />
              <h5>We believe in empowering people through technology to deliver exceptional results.</h5>
            </header>
            <h2>Meet Our Specialists</h2>
            <ul className="features">
              <li className="icon fa-paper-plane">
                <h3>Business Analysts</h3>
                <p>
                  Uncovering opportunities to enhance organizational efficiency with strategic AI insights.
                </p>
              </li>
              <li className="icon solid fa-laptop">
                <h3>Project Managers</h3>
                <p>
                  Leading the charge in AI adoption, ensuring goals align with successful project delivery.
                </p>
              </li>
              <li className="icon solid fa-code">
                <h3>Data Scientists</h3>
                <p>
                  Turning raw data into valuable insights, developing predictive models and AI solutions.
                </p>
              </li>
              <li className="icon solid fa-headphones-alt">
                <h3>AI Engineers</h3>
                <p>
                  Designing and deploying intelligent AI models, from chatbots to recommendation engines.
                </p>
              </li>
              {/* <li className="icon fa-heart">
                <h3>AWS Cloud Engineers</h3>
                <p>
                  Scaling AI solutions on the cloud with secure, flexible infrastructure.
                </p>
              </li>
              <li className="icon fa-flag">
                <h3>Microsoft Platform Specialists</h3>
                <p>
                  Building internal and external AI tools on Microsoft Power Platform and Dynamics.
                </p>
              </li> */}
            </ul>
          </div>
        </section>


       {/* <section id="cta" className="wrapper style2">
          <div className="inner">
             <Contact /> 
          </div>
        </section>*/}
      </Layout>
    </BackgroundImage>
  );
});

export default IndexPage;
